import styled, { css } from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
// Sizes \\
// Medium
const mediumSizeStyle = css `
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  height: 2.75rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
// Large
const largeSizeStyle = css `
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  height: 4.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
// Variants \\
// Placeholder
const placeholderVariantStyle = css `
  color: ${getThemeColor(ThemeColor.Dark, 0.2)};
  border: 0.125rem dashed ${getThemeColor(ThemeColor.BorderDark, 0.12)};
  justify-content: center;
`;
// Add
const addVariantStyle = css `
  cursor: pointer;
  background-color: ${getThemeColor(ThemeColor.BrandDark)};
  color: ${getThemeColor(ThemeColor.White)};
  border: none;
  justify-content: space-between;
  outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: ${getThemeColor(ThemeColor.Secondary)};
  }
`;
const CardAdd = styled.button `
  min-width: 15rem;
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  ${({ size }) => {
    switch (size) {
        case 'large':
            return largeSizeStyle;
        case 'medium':
        default:
            return mediumSizeStyle;
    }
}}

  ${({ variant }) => {
    switch (variant) {
        case 'add':
            return addVariantStyle;
        case 'placeholder':
        default:
            return placeholderVariantStyle;
    }
}}
`;
export default {
    CardAdd,
};
