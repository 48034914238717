var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { nonUndefined } from '@happn/helpers';
import loggerService from '@happn/logger';
import { useTraitsList } from '@happn/traits';
import { USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_SAVE_TRAIT_ANSWER_MUTATION, USER_TRAITS_QUERY, USER_REMOVE_TRAIT_ANSWER_MUTATION, } from './gql';
const useTraits = (id = USER_ID_ME) => {
    var _a, _b;
    const client = useApolloClient();
    const { data } = useQuery(USER_TRAITS_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const traitsList = useTraitsList();
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const saveAnswer = useCallback((traitId, input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield client.mutate({
                mutation: USER_SAVE_TRAIT_ANSWER_MUTATION,
                variables: {
                    userId: id,
                    traitId,
                    input,
                },
                update(cache, { data }) {
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            traits(current) {
                                const traitIndex = current.findIndex((t) => t.id === traitId);
                                if (!(data === null || data === void 0 ? void 0 : data.userSaveTraitAnswer) || traitIndex === -1) {
                                    return current;
                                }
                                return update(current, {
                                    [traitIndex]: {
                                        answer: {
                                            $set: data.userSaveTraitAnswer.answer,
                                        },
                                        rawAnswer: {
                                            $set: data.userSaveTraitAnswer.rawAnswer,
                                        },
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not save trait answer, ${err}`));
            return false;
        }
    }), [client, id, userId]);
    const removeAnswer = useCallback((traitId) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        try {
            const response = yield client.mutate({
                mutation: USER_REMOVE_TRAIT_ANSWER_MUTATION,
                variables: { userId: id, traitId },
                update(cache, { data }) {
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            traits(current) {
                                const traitIndex = current.findIndex((t) => t.id === traitId);
                                if (!(data === null || data === void 0 ? void 0 : data.userRemoveTraitAnswer.success) ||
                                    traitIndex === -1) {
                                    return current;
                                }
                                return update(current, {
                                    [traitIndex]: {
                                        answer: { $set: '' },
                                        rawAnswer: { $set: null },
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return ((_c = response.data) === null || _c === void 0 ? void 0 : _c.userRemoveTraitAnswer.success) || false;
        }
        catch (err) {
            loggerService.error(new Error(`Could not remove trait answer, ${err}`));
            return false;
        }
    }), [client, id, userId]);
    const getMatchingTraitAnswer = (traitsList, trait) => {
        var _a, _b, _c;
        switch (trait.type) {
            case 'FLOAT_RANGE': {
                return trait.answer;
            }
            case 'SINGLE_CHOICE': {
                const traitById = traitsList.find((t) => t.id === trait.id);
                const matchingAnswer = (_b = (_a = traitById === null || traitById === void 0 ? void 0 : traitById.options) === null || _a === void 0 ? void 0 : _a.singleChoice) === null || _b === void 0 ? void 0 : _b.find((option) => option.id === trait.answer);
                return (_c = matchingAnswer === null || matchingAnswer === void 0 ? void 0 : matchingAnswer.defaultText) !== null && _c !== void 0 ? _c : '';
            }
            default: {
                return '';
            }
        }
    };
    const getMatchingTraitMultiAnswers = (traitsList, multiTrait) => {
        var _a, _b, _c;
        if (multiTrait.type !== 'MULTI_CHOICE') {
            return [];
        }
        const traitById = (_b = (_a = traitsList.find((t) => t.id === multiTrait.id)) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.multiChoices;
        return (_c = multiTrait === null || multiTrait === void 0 ? void 0 : multiTrait.answers) === null || _c === void 0 ? void 0 : _c.map((trait) => {
            var _a, _b;
            return {
                optionId: trait.optionId,
                categoryId: trait.categoryId,
                defaultText: (_a = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.id === trait.optionId)) === null || _a === void 0 ? void 0 : _a.defaultText,
                categoryText: (_b = traitById === null || traitById === void 0 ? void 0 : traitById.find((t) => t.categoryId === trait.categoryId)) === null || _b === void 0 ? void 0 : _b.defaultText,
            };
        });
    };
    const traitsWithAnswers = (_b = (_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.traitsV2) === null || _b === void 0 ? void 0 : _b.map((trait) => {
        var _a, _b, _c, _d, _e, _f;
        const matchingTrait = traitsList.find((singleTrait) => singleTrait.id === trait.id);
        return {
            id: (_a = matchingTrait === null || matchingTrait === void 0 ? void 0 : matchingTrait.id) !== null && _a !== void 0 ? _a : '',
            type: (_b = matchingTrait === null || matchingTrait === void 0 ? void 0 : matchingTrait.type) !== null && _b !== void 0 ? _b : '',
            answer: (_c = getMatchingTraitAnswer(traitsList !== null && traitsList !== void 0 ? traitsList : '', trait)) !== null && _c !== void 0 ? _c : '',
            answers: (_d = getMatchingTraitMultiAnswers(traitsList !== null && traitsList !== void 0 ? traitsList : '', trait)) !== null && _d !== void 0 ? _d : [],
            shortTitle: (_e = matchingTrait === null || matchingTrait === void 0 ? void 0 : matchingTrait.shortTitle) !== null && _e !== void 0 ? _e : '',
            icon: (_f = matchingTrait === null || matchingTrait === void 0 ? void 0 : matchingTrait.icon) !== null && _f !== void 0 ? _f : '',
        };
    }).filter(nonUndefined);
    return {
        traits: (data === null || data === void 0 ? void 0 : data.user.traits) || [],
        traitsV2: traitsWithAnswers || [],
        saveAnswer,
        removeAnswer,
    };
};
export default useTraits;
