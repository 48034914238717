import { nonUndefined } from '@happn/helpers';
export const getSingleChoiceOption = (option) => {
    var _a;
    if (!(option === null || option === void 0 ? void 0 : option.default_text) || !(option === null || option === void 0 ? void 0 : option.id)) {
        return undefined;
    }
    return {
        id: option.id,
        defaultText: option.default_text,
        translatedText: ((_a = option === null || option === void 0 ? void 0 : option.translated_text) === null || _a === void 0 ? void 0 : _a[0]) || null,
    };
};
export const getMultiChoicesOption = (option) => {
    return Object.entries(option).flatMap(([key, value]) => {
        return value.map((item) => {
            var _a, _b, _c;
            return ({
                id: (_a = item.id) !== null && _a !== void 0 ? _a : '',
                defaultText: (_b = item.default_text) !== null && _b !== void 0 ? _b : '',
                translatedText: ((_c = item === null || item === void 0 ? void 0 : item.translated_text) === null || _c === void 0 ? void 0 : _c[0]) || null,
                categoryId: key,
            });
        });
    });
};
export const getOptions = (gto) => {
    switch (gto.type) {
        case 'FLOAT_RANGE':
            const floatRangeOption = gto.options;
            if (!(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.default_value_female) ||
                !(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.default_value_male) ||
                !(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.min_value) ||
                !(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.max_value) ||
                !(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.metric) ||
                !(floatRangeOption === null || floatRangeOption === void 0 ? void 0 : floatRangeOption.step)) {
                return null;
            }
            return {
                type: 'FLOAT_RANGE',
                floatRange: {
                    defaultValueFemale: floatRangeOption.default_value_female,
                    defaultValueMale: floatRangeOption.default_value_male,
                    minValue: floatRangeOption.min_value,
                    maxValue: floatRangeOption.max_value,
                    metric: floatRangeOption.metric,
                    step: floatRangeOption.step,
                },
            };
        case 'USER_INPUT':
            // @TODO: Not handle yet : teasers
            return null;
        case 'SINGLE_CHOICE':
            const singleChoiceOptions = gto.options;
            return {
                type: 'SINGLE_CHOICE',
                singleChoice: (singleChoiceOptions === null || singleChoiceOptions === void 0 ? void 0 : singleChoiceOptions.map(getSingleChoiceOption).filter(nonUndefined)) || null,
            };
        case 'MULTI_CHOICE':
            const multiChoicesOptions = gto.options;
            return {
                type: 'MULTI_CHOICE',
                multiChoices: getMultiChoicesOption(multiChoicesOptions),
            };
        default:
            return null;
    }
};
const getCategories = (categories) => {
    if (!(categories === null || categories === void 0 ? void 0 : categories.length)) {
        return [];
    }
    return categories.map((category) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            id: (_a = category.id) !== null && _a !== void 0 ? _a : '',
            icon: (_b = category.category_icon_url) !== null && _b !== void 0 ? _b : '',
            categoryText: (_c = category.category_text) !== null && _c !== void 0 ? _c : '',
            translatedText: (_f = (_e = (_d = category === null || category === void 0 ? void 0 : category.translated_text) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '',
        });
    });
};
export const getTraitsList = (gto) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        id: gto === null || gto === void 0 ? void 0 : gto.id,
        name: gto === null || gto === void 0 ? void 0 : gto.name,
        type: gto === null || gto === void 0 ? void 0 : gto.type,
        order: gto === null || gto === void 0 ? void 0 : gto.order,
        sensitive: !!(gto === null || gto === void 0 ? void 0 : gto.sensitive),
        matchingPref: !!(gto === null || gto === void 0 ? void 0 : gto.matching_pref),
        icon: (gto === null || gto === void 0 ? void 0 : gto.icon_image_url) + '?width=64&height=64&mode=0',
        mainTitle: (_c = (_b = (_a = gto.titles.main_title.translated_text) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '',
        shortTitle: (_f = (_e = (_d = gto.titles.short_title.translated_text) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '',
        options: getOptions(gto),
        categories: getCategories(gto === null || gto === void 0 ? void 0 : gto.categories),
    };
};
