import { ApiTypes, GqlTypes } from '@happn/types';
import { UserProfileType, VerificationStatusType, } from './types';
export const user = {
    id: '0298cd51-440f-42bf-85ca-2d848ffaee35',
    firstName: 'Jack',
    lastName: 'Sparoł',
    gender: GqlTypes.Gender.Male,
    genderAlias: {
        id: '45b9e8a7-c49e-4fa3-af24-a80c6430d3b8',
        label: 'Transgénero',
        gender: GqlTypes.Gender.Male,
        defaultLabel: 'Transgender',
    },
    audios: [],
    about: 'Jamais sans mon sourire et ma joie de vivre \u2764\ufe0f !\n\nJ’adore cuisiner, voyager, refaire le monde entre amis, rire, imaginer, essayer, me tromper et recommencer…',
    age: 25,
    distance: 250,
    isAccepted: false,
    onlineStatus: GqlTypes.OnlineStatus.Unknown,
    hasCharmedMe: false,
    isCharmed: false,
    isAdmin: false,
    myRelations: null,
    crossingsCount: 0,
    firstIp: {
        country: '',
        value: '',
    },
    country: {
        id: '',
        name: '',
    },
    language: {
        id: '',
        name: '',
    },
    lastMeetPosition: null,
    pictures: [
        {
            id: '23da3c50-7e5b-11ea-b94c-c30ee2a93c7b',
            url: 'https://mvistatic.com/photosmvi/2018/08/03/P17926537D3502874G.jpg',
            isDefault: false,
        },
        {
            id: '23da3c50-7e5b-11ea-b94c-c30ee2a93c7c',
            url: 'https://preprod-images.happn.com/resizing/5a2e7555-8144-4c64-a8fb-52c214243101/19aad150-7e50-11ea-b94c-c30ee2a93c7b.jpg?width=320&height=320&mode=0',
            isDefault: false,
        },
        {
            id: '23da3c50-7e5b-11ea-b94c-c30ee2a93c7d',
            url: 'https://www.voyage.fr/sites/default/files/styles/1170x530/public/2018-04/00-les-paysages-russes-a-voir-absolument.jpg?itok=V_lDD5Ip',
            isDefault: false,
        },
    ],
    traits: [
        {
            id: '58c4ffd0-772d-11e9-9403-ab4bdd6fe9b1',
            emoji: '\ud83d\udd0e',
            label: 'What are you looking for on happn?',
            shortLabel: '',
            answer: 'Nothing serious',
            name: '',
        },
        {
            id: 'a23938b0-77f6-11e9-b0dc-35a91441a495',
            emoji: '\ud83d\ude0a',
            label: 'How tall are you?',
            shortLabel: '',
            answer: '172cm (5’64”)',
            name: '',
        },
        {
            id: 'bae69d90-77e6-11e9-b4c2-c3a9c0a73afd',
            emoji: '\ud83d\udcaa',
            label: 'What are your exercise habits?',
            shortLabel: '',
            answer: 'All exercise all the time',
            name: '',
        },
        {
            id: 'd61867c0-77f4-11e9-b43e-ed07d0af7b4d',
            emoji: '\ud83c\udf74',
            label: 'Which answer best describes your cooking skills?',
            shortLabel: '',
            answer: 'I\u2019m a microwave master',
            name: '',
        },
        {
            id: '98aac8a0-77eb-11e9-b4c2-c3a9c0a73afd',
            emoji: '\u2708\ufe0f',
            label: 'What two words best describe your ideal vacation?',
            shortLabel: '',
            answer: 'Hiking & backpack',
            name: '',
        },
        {
            id: 'f5ce5f90-91e2-11e9-86f7-9119d852bbdd',
            emoji: '\ud83c\udf89',
            label: 'When it comes to the nightlife\u2026',
            shortLabel: '',
            answer: 'I party in moderation',
            name: '',
        },
        {
            id: 'd2df1924-62fe-11ee-8c99-0242ac120002',
            emoji: '\ud83c\udf89',
            label: 'What is your zodiac sign?',
            shortLabel: '',
            answer: 'Virgo',
            name: '',
        },
        {
            id: '73210140-772c-11e9-9403-ab4bdd6fe9b1',
            emoji: '\ud83c\udf89',
            label: 'What are you looking for on happn?',
            shortLabel: '',
            answer: 'Nothing serious',
            name: '',
        },
    ],
    traitsV2: [
        {
            type: 'SINGLE_CHOICE',
            id: '31454f40-7ef3-11ea-9cbc-072a91dcea22',
            answer: '3edb7e30-7ef9-11ea-9cbc-072a91dcea22',
        },
        {
            type: 'SINGLE_CHOICE',
            id: '73210140-772c-11e9-9403-ab4bdd6fe9b1',
            answer: '5efb1e20-772d-11e9-9403-ab4bdd6fe9b1',
        },
        {
            type: 'SINGLE_CHOICE',
            id: '79c98710-7ef1-11ea-9cbc-072a91dcea22',
            answer: 'f502f010-7ef1-11ea-9cbc-072a91dcea22',
        },
        {
            type: 'FLOAT_RANGE',
            id: 'a23938b0-77f6-11e9-b0dc-35a91441a495',
            answer: {
                value: 1.65,
                metric: 'length',
            },
        },
        {
            type: 'SINGLE_CHOICE',
            id: 'bae69d90-77e6-11e9-b4c2-c3a9c0a73afd',
            answer: '3f3fe1e0-77e8-11e9-b4c2-c3a9c0a73afd',
        },
        {
            type: 'SINGLE_CHOICE',
            id: 'd2df1924-62fe-11ee-8c99-0242ac120002',
            answer: '06154a1c-6303-11ee-8c99-0242ac120002',
        },
        {
            type: 'USER_INPUT',
            id: 'd972621e-d1d8-4352-9ff1-8a53f0b0cdfe',
            answer: [
                {
                    categoryId: '411907ca-302d-4fff-a23f-44bd4241e826',
                    optionId: '4b9010c9-a03b-4c33-9ae0-697518983fa3',
                    answerText: 'this is my answer 1',
                },
                {
                    categoryId: '411907ca-302d-4fff-a23f-44bd4241e826',
                    optionId: 'f8aa725d-cb75-4cbe-b96a-501300e00653',
                    answerText: 'this is my answer 2',
                },
            ],
        },
    ],
    job: 'Agent secret',
    school: 'IUT de Meaux',
    workplace: 'FBI',
    spotifyTracksId: [],
    socialMedia: {
        instagram: {
            pictures: [],
        },
        facebook: {
            id: '',
            email: '',
        },
        google: {
            id: '',
            email: '',
        },
        apple: {
            id: '',
            email: '',
        },
    },
    isSponsor: false,
    isModerator: false,
    verification: {
        status: VerificationStatusType.Unverified,
        reason: '',
    },
    balance: [],
    birthdate: '',
    email: '',
    isSub: false,
    lastAcceptedVersions: {
        tos: '',
        cookieV1: '',
        cookieV2: '',
        cookieV3: '',
        sdc: '',
    },
    locationCity: null,
    residenceCity: null,
    pendingLikers: null,
    deviceInfo: 0,
    unreadInfo: {
        conversations: 0,
        notifications: 0,
    },
    subscription: null,
    sensitiveTraitsPreferences: {
        sensitiveTraitsAccepted: false,
    },
    hideGenderAlias: true,
    subscriptionLevel: ApiTypes.SubscriptionLevelEnum.Freemium,
    teaserAnswers: [
        {
            teaserId: '747bdddb-6f6e-41e7-bb36-deba28fa9105',
            answerText: 'Le temps.',
            teaserText: 'My worst daily enemy',
            translatedTeaser: 'My worst daily enemy',
        },
        {
            teaserId: '8f680f20-70d1-43f3-bd61-695765c89051',
            answerText: 'En Laponie.',
            teaserText: 'If I need to disappear, I will go to',
            translatedTeaser: 'If I need to disappear, I will go to',
        },
        {
            teaserId: '92e15ee9-d07a-4921-b1d1-4873693d012f',
            answerText: 'Neil Armstrong a vraiment marché sur la lune ?',
            teaserText: 'I’m not a conspiracy theorist but',
            translatedTeaser: 'I’m not a conspiracy theorist but',
        },
    ],
};
export const crossingUserProfile = {
    user,
    type: UserProfileType.Crossing,
    position: {
        lat: 48.868526,
        lon: 2.345656,
        modificationDate: new Date('2021-01-01'),
    },
    crossingsCount: 1,
};
export const giftUserProfile = {
    user,
    type: UserProfileType.Gift,
};
export const defaultPreferences = {
    location: {
        hide: false,
    },
    marketing: {
        audienceMeasurement: false,
        operations: false,
        recommendedInEmails: false,
        targetedAds: false,
    },
    matching: {
        ageMax: 40,
        ageMin: 20,
        female: false,
        male: false,
        nonBinary: false,
    },
    mysteriousMode: {
        hideGenderAlias: true,
    },
    notifications: {
        charms: 0,
        crush: 0,
        crushTime: 0,
        dailyRecap: 0,
        news: 0,
        others: 0,
        weeklyRecap: 0,
        dates: 0,
        likes: 0,
        messages: 0,
        near: 0,
        reminders: 0,
        visit: 0,
    },
    biometric: {
        profileVerification: {
            accepted: false,
        },
    },
    matchingTraits: [],
};
export const order = {
    id: 'xxx',
    channel: ApiTypes.SubscriptionGtoChannelEnum.INTERNAL,
    creationDate: new Date('2021-05-05'),
    currency: 'EUR',
    label: 'product-label',
    ordersCount: 5,
    quantity: 1,
    releaseDate: new Date('2021-05-05'),
    sandbox: true,
    totalAmountAti: 123,
};
const subscriptionBase = {
    channel: ApiTypes.SubscriptionGtoChannelEnum.STRIPE,
    endDate: new Date('2021-05-05'),
    introductory: false,
    reactivation: true,
    startDate: new Date('2021-05-05'),
    status: 'xxx',
    userId: 'zzz',
    level: ApiTypes.SubscriptionLevelEnum.Premium,
};
export const internalSubscription = Object.assign(Object.assign({}, subscriptionBase), { id: 'internal', type: ApiTypes.SubscriptionTypeEnum.Internal });
export const purchasedSubscription = Object.assign(Object.assign({}, subscriptionBase), { id: 'purchased', type: ApiTypes.SubscriptionTypeEnum.Purchased });
export const subscriptionOrder = {
    amount: 10,
    channel: ApiTypes.SubscriptionGtoChannelEnum.STRIPE,
    currency: 'EUR',
    id: 'xxx',
    purchaseDate: new Date('2021-05-05'),
    releaseDate: new Date('2021-05-05'),
    sandbox: true,
    storeProductId: 'yyy',
};
export const userArchive = {
    archiveReason: ApiTypes.ArchiveReasonEnum.Manual,
    archiveState: ApiTypes.ArchiveStateEnum.Created,
    archiveType: ApiTypes.ArchiveTypeEnum.Html,
    creationDate: new Date('2021-05-05'),
    id: 'xxx',
    requesterId: 'yyy',
    userId: 'zzz',
    isDone: true,
    processStartDate: new Date('2021-05-05'),
    requesterComment: 'Comment',
};
export const usersExport = {
    exportDate: new Date('2021-05-05'),
    exporterUserId: 'xxx',
    fields: ['id'],
    fileName: 'xxx.csv',
    filter: '{}',
    id: 'yyy',
    progressPercentage: 50,
    signedUrl: 'url.com',
};
