import { ApolloLink } from '@apollo/client';
import { v4 as uuidV4 } from 'uuid';
import env from '@happn/env';
export const createAuthorizationLink = (apolloService) => {
    return new ApolloLink((operation, forward) => {
        operation.setContext(({ headers }) => {
            var _a, _b;
            const additionalHeaders = (_b = (_a = apolloService.headersExtender) === null || _a === void 0 ? void 0 : _a.call(apolloService, operation)) !== null && _b !== void 0 ? _b : {};
            return {
                headers: Object.assign(Object.assign(Object.assign({}, headers), additionalHeaders), { 'x-happn-version': `happn-webapp/${env.common.version}`, 'X-Happn-CID': uuidV4() }),
            };
        });
        return forward(operation);
    });
};
