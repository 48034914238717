import { gql } from '@apollo/client';
import { UserTypeName, UserAudioTypeName, UserOrderTypeName } from './types';
export const USER_BASE_FRAGMENT = gql `
  fragment UserBase on ${UserTypeName} {
    id
    firstName
    lastName
    gender
    genderAlias
    hideGenderAlias
    age
    about
    job
    workplace
    school
    birthdate
    lastActivityDate
    onlineStatus
    isModerator
    isSponsor
    status
    lastPositionUpdate
    registerDate
    residenceCity
  }
`;
export const USER_ABOUT_FRAGMENT = gql `
  fragment UserAbout on ${UserTypeName} {
    id
    about
  }
`;
export const USER_PICTURES_FRAGMENT = gql `
  fragment UserPictures on ${UserTypeName} {
    picture {
      id
      url
      isDefault
    }
    pictures {
      id
      url
      isDefault
    }
  }
`;
export const USER_SOCIAL_MEDIA_FRAGMENT = gql `
  fragment UserSocialMedia on ${UserTypeName} {
    socialMedia {
      instagram {
        pictures {
          id
          createdAt
          description
          link
          images {
            url
          }
        }
      }
      facebook {
        id
        email
      }
      google {
        id
        email
      }
      apple {
        id
        email
      }
    }
  }
`;
export const USER_TRAITS_FRAGMENT = gql `
  fragment UserTraits on ${UserTypeName} {
    traits {
      id
      emoji
      label
      shortLabel
      answer
      name
      type
      sensitive
      rawAnswer {
        type
        single {
          id
        }
        float_range {
          value
          metric
        }
      }
    }
  }
`;
export const USER_TRAITS_V2_FRAGMENT = gql `
  fragment UserTraitsV2 on ${UserTypeName} {
    traitsV2 {
      id
      type
      answer
      answers
      rawAnswer {
        type
        single {
          id
        }
        float_range {
          value
          metric
        }
        multi {
          categoryId
          optionId
        }
      }
    }
  }
`;
export const USER_RELATIONS_FRAGMENT = gql `
  fragment UserRelations on ${UserTypeName} {
    isCharmed
    hasCharmedMe
    isAccepted
    myRelations {
      accepted
      blocked
      rejected
      mutual
    }
  }
`;
export const USER_UNREAD_FRAGMENT = gql `
  fragment UserUnreadInfo on ${UserTypeName} {
    unreadInfo {
      conversations
      notifications
    }
  }
`;
export const USER_CROSSING_FRAGMENT = gql `
  fragment UserCrossing on ${UserTypeName} {
    crossingsCount
    lastMeetPosition {
      lat
      lon
      modificationDate
    }
  }
`;
export const USER_VERIFICATION_FRAGMENT = gql `
  fragment UserVerification on ${UserTypeName} {
    verification {
      status
      reason
    }
  }
`;
export const USER_PENDING_LIKERS_FRAGMENT = gql `
  fragment UserPendingLikers on ${UserTypeName}  {
    pendingLikers {
      label
      iconUrl
    }
  }
`;
export const USER_BALANCE_FRAGMENT = gql `
  fragment UserBalance on ${UserTypeName}  {
    isSub
    balance {
      type
      total
      permanent
      renewable
      renewablePerPeriod
      cooldownPeriod
      cooldownTimeLeft
    }
  }
`;
export const USER_LOCATION_CITY_FRAGMENT = gql `
  fragment UserLocationCity on ${UserTypeName}  {
    locationCity {
      id
      city
      county
      country
      display
      position {
        longitude
        latitude
      }
    }
  }
`;
export const USER_STATUS_FRAGMENT = gql `
  fragment UserStatus on ${UserTypeName} {
    status
    isSub
    onlineStatus
    isSponsor
    isModerator
  }
`;
export const USER_LAST_ACCEPTED_VERSIONS_FRAGMENT = gql `
  fragment UserLastAcceptedVersions on ${UserTypeName} {
    lastAcceptedVersions {
      tos
      sdc
      cookieV1
      cookieV2
      cookieV3
    }
  }
`;
export const USER_AUDIO_FRAGMENT = gql `
  fragment UserAudio on ${UserAudioTypeName} {
    id
    creationDate
    url
    topic
    durationInSeconds
  }
`;
export const USER_PROFILE_FRAGMENT = gql `
  ${USER_BASE_FRAGMENT}
  ${USER_PICTURES_FRAGMENT}
  ${USER_SOCIAL_MEDIA_FRAGMENT}
  ${USER_TRAITS_FRAGMENT}
  ${USER_RELATIONS_FRAGMENT}
  ${USER_CROSSING_FRAGMENT}
  ${USER_VERIFICATION_FRAGMENT}

  fragment UserProfile on UserProfile {
    type
    crossingsCount
    position {
      lat
      lon
      modificationDate
    }
    user {
      ...UserBase
      ...UserPictures
      ...UserSocialMedia
      ...UserTraits
      ...UserRelations
      ...UserCrossing
      ...UserVerification
      spotifyTracksId
      distance
      sensitiveTraitsPreferences
    }
  }
`;
export const USER_ORDER_BASE_FRAGMENT = gql `
  fragment UserOrderBase on ${UserOrderTypeName} {
    id
    channel
    label
    quantity
    creationDate
    currency
    releaseDate
    sandbox
    totalAmountAti
    ordersCount
  }
`;
