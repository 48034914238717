var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { USER_ID_ME, USER_QUERY, } from '../../apollo';
import { GET_TEASERS_QUERY, UPDATE_TEASER_ANSWER_MUTATION, DELETE_TEASER_ANSWER_MUTATION, } from './gql';
const useTeasers = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(GET_TEASERS_QUERY, {
        fetchPolicy: 'cache-only',
    });
    const fetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.query({
                query: GET_TEASERS_QUERY,
            });
            if (!(data === null || data === void 0 ? void 0 : data.teasersList)) {
                loggerService.error(new Error('Could not get teasers list'));
                return Promise.reject();
            }
            return data.teasersList;
        }
        catch (err) {
            loggerService.error(new Error(`Could not get teasers list, ${err}`));
            return Promise.reject(err);
        }
    }), [apolloClient]);
    const updateAnswer = useCallback((teaser, overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: UPDATE_TEASER_ANSWER_MUTATION,
                variables: {
                    id: overrideId || id,
                    teaserId: teaser.teaserId,
                    input: {
                        answer_text: teaser.answerText,
                    },
                },
                refetchQueries: [{ query: USER_QUERY, variables: { id } }],
            });
            return !!(data === null || data === void 0 ? void 0 : data.teasersUpdate.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not update user teaser, ${err}`));
            return false;
        }
    }), [apolloClient, id]);
    const deleteAnswer = useCallback((teaserId, overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield apolloClient.mutate({
                mutation: DELETE_TEASER_ANSWER_MUTATION,
                variables: {
                    id: overrideId || id,
                    teaserId,
                },
                refetchQueries: [{ query: USER_QUERY, variables: { id } }],
            });
            if (!((_a = data === null || data === void 0 ? void 0 : data.deleteAnswer) === null || _a === void 0 ? void 0 : _a.success)) {
                loggerService.error(new Error('Could not delete teaser answer'));
                return Promise.reject(false);
            }
            return Promise.resolve(true);
        }
        catch (err) {
            loggerService.error(new Error(`Could not delete teaser answer, ${err}`));
            return Promise.reject(err);
        }
    }), [apolloClient, id]);
    return { fetch, teasers: data === null || data === void 0 ? void 0 : data.teasersList, updateAnswer, deleteAnswer };
};
export default useTeasers;
